import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "lazysizes"
import { MobileMenu, DesktopMenu } from "./menu"
const Navigation = props => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allListings {
        totalCount
        edges {
          node {
            monthPassAvailable
            dayPassAvailable
            hourPassAvailable
            officeSpaceType
            purposesList
            spaceAddress
            spaceGFName
            OriginalName
            spaceCity
            spaceId
            spaceImage
            spaceTitle
            spaceDesc
            spaceType
            subType
            priceDay
            priceHr
            priceMonth
            spaceDisplayName
            Facility
            slug
            hasCovidSafeBadge
            online
            Rating
          }
        }
      }
      allCities {
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
    }
  `)

  const [menuState, setmenuState] = useState("close")

  const MenuOpen = () => {
    if (menuState === "open") {
      setmenuState("close")
    } else {
      setmenuState("open")
    }
    //console.log(menuState)
  }
  const [scrollState, setscrollState] = useState("top")
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setscrollState("top")
    } else if (window.scrollY !== 0) {
      setscrollState("onmoving")
    }
  }
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleScroll)
  }
  return (
    <div>
      <header className={"header"}>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand logo center-logo" to="/">
              {props.whiteLogo === "active" ? (
                <img
                  src="https://assets.gofloaters.com/logo-white.png"
                  alt="GoFloaters"
                  width="250"
                  height="70"
                  className="white"
                />
              ) : (
                <img
                  src="https://assets.gofloaters.com/logo.png"
                  alt="GoFloaters"
                  width="250"
                  height="70"
                  className="white"
                />
              )}
            </Link>
            <button
              className="navbar-toggler"
              name="mobilenavigation"
              type="button"
              aria-label="Navigation Button"
              onClick={MenuOpen}
            >
              <i className="fa fa-navicon"></i>
            </button>

            <DesktopMenu data={data} />
          </nav>
        </div>
      </header>
      <div className={"Nav mobile-only " + menuState}>
        <div className="text-right">
          <button
            className="navbar-toggler"
            name="MobileNavigation"
            type="button"
            aria-label="Navigation Button"
            onClick={MenuOpen}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
        <MobileMenu data={data} />
        <div className="padding-20"></div>
        {/* <div className="text-center">
          <a
            href="https://www.gofloaters.com/app"
            target="_blank"
            className="listyourspace"
          >
            Login
          </a>
        </div> */}
        <div className="padding-20"></div>
        <br></br>
        <Link className="navbar-brand logo" to="/">
          <img
            data-src="https://assets.gofloaters.com/logo.png"
            className="lazyload"
            alt="GoFloaters"
            width="250"
            height="70"
          />
        </Link>
      </div>
    </div>
  )
}

export default Navigation
